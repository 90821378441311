<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import axios from "axios";
import Swal from "sweetalert2";
import { containsDisallowedCharacters } from "../../../../utils/check-disallowed-character";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Isi Kuesioner",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },

  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Isi Kuesioner",
      // Catch Error Axios
      axiosCatchError: null,

      data_soal: [],
      // variable Page
      form_data: [
        {
          id_jadwal: "",
          id_kuesioner: "",
          id_simulasi: "",
          nip_asesi: "",
          pin: "",
          is_combo: "NO",
          pertanyaan: "",
          jawaban: "",
        },
      ],

      id_jadwal: null,
      id_simulasi: null,
      nip: null,
      pin: null,
    };
  },
  mounted() {
    this.getSoal();
  },
  methods: {
    getSoal() {
      let self = this;
      self.id_jadwal = self.$route.params.id_jadwal;
      self.id_simulasi = self.$route.params.id_simulasi;
      self.pin = self.$route.params.pin;
      self.nip = localStorage.session_username;

      var id_jadwal = self.id_jadwal;
      var id_simulasi = self.id_simulasi;
      var nip = self.nip;
      var pin = self.pin;
      var is_combo = "YES";

      var config_get_soal = {
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + `master/kuisioner/by-id/${id_jadwal}/${id_simulasi}/${nip}/${is_combo}`,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config_get_soal)
        .then(function (response) {
          self.data_soal = response.data.data;
          self.form_data = [];

          self.data_soal.forEach((item) => {
            self.form_data.push({
              id_jadwal: item.idjadwal,
              id_kuesioner: item.kuesioner_id,
              id_simulasi: item.simulasi_id,
              nip_asesi: nip,
              pin: pin,
              is_combo: "YES",
              pertanyaan: item.pertanyaan,
              is_likert: item.show_likert,
              is_essay: item.show_essay_condition,
              is_essay_value_likert: item.show_essay_value_likert,
              header_essay: item.header_essay,
              list_pilihan: item.jawaban.map((jawabanItem) => ({
                value: jawabanItem.value_likert,
                label: jawabanItem.text_likert,
                name: jawabanItem.id_kuesioner,
              })),
              jawaban: null,
              jawaban_essay: null,
            });
          });

          console.log("Data");
        })
        .catch(function (error) {
          self.axiosCatchError = error.response.data.data;
        });
    },
    // Method dari masing masing fungsi di dalam file ini
    StoreData() {
      let self = this;

      this.form_data.forEach((element) => {
        if (containsDisallowedCharacters(element.jawaban)) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Terdapat karakter yang tidak diperbolehkan!",
          });
          return; // Exit the function if validation fails
        }
      });

      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });

      var config_store_data = {
        method: "post",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/kuisioner/store-kuesioner",
        data: this.form_data,
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.access_token,
        },
      };

      axios(config_store_data)
        .then(function (response) {
          console.log(response);
          Swal.fire({
            icon: "success",
            title: "Berhasil",
            text: "Anda akan diarahkan ke halaman master simulasi config segera",
            timer: 1000,
            timerProgressBar: true,
            showCancelButton: false,
            showConfirmButton: false,
          }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
              Swal.close();
              self.$router.push({
                name: "detail-jadwal",
                params: {
                  id: self.id_jadwal,
                },
              });
            }
          });
        })
        .catch(function (error) {
          self.axiosCatchError = error.response.data.data;
          Swal.close();
        });
    },
  },
};
</script>

<style scoped>
.btn-check:checked + .btn-info,
.btn-check:active + .btn-info,
.btn-info:active,
.btn-info.active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #003462;
  border-color: #3c7cb5;
}
</style>
<template>
  <Layout>
    <PageHeader :title="title" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <b-form class="p-2" @submit.prevent="StoreData">
            <div class="card-body">
              <div v-if="axiosCatchError">
                <div class="alert alert-danger" v-if="typeof axiosCatchError === 'string' || axiosCatchError instanceof String">
                  {{ axiosCatchError }}
                </div>
                <div class="alert alert-danger" role="alert" v-else>
                  <div v-for="(errorArray, idx) in axiosCatchError" :key="idx">
                    <div v-for="(allErrors, idx) in errorArray" :key="idx">
                      <span class="text-danger">{{ allErrors[0] }} </span>
                    </div>
                  </div>
                </div>
              </div>
              <table class="table table-borderless">
                <template v-for="(soal, key) in form_data" :key="key">
                  <tr>
                    <th class="fs-5" style="width: 15px">{{ key + 1 }}</th>
                    <th>
                      <label class="pe-0 fw-bold fs-5">{{ soal.pertanyaan }}</label>
                    </th>
                  </tr>
                  <tr>
                    <td></td>
                    <td v-if="soal.is_likert == 1">
                      <div class="col-12">
                        <template v-for="(pilihan, p_key) in form_data[key].list_pilihan" :key="p_key">
                          <input type="radio" class="btn-check" :id="'input_' + key + '_' + p_key" :name="pilihan.name" :value="pilihan.value" v-model="form_data[key].jawaban" />
                          <label class="btn btn-info me-2" :for="'input_' + key + '_' + p_key">{{ pilihan.label }}</label>
                        </template>
                      </div>
                      <div class="col-12" v-if="form_data[key].jawaban <= soal.is_essay_value_likert && soal.is_essay == 1">
                        <label class="pe-0 fw-bold fs-5">{{ soal.header_essay }}</label>
                        <textarea v-model="form_data[key].jawaban_essay" class="form-control" name="textarea" rows="2" required></textarea>
                      </div>
                    </td>
                    <td v-else>
                      <div class="col-12">
                        <textarea v-model="form_data[key].jawaban" class="form-control" name="textarea" rows="5" required></textarea>
                      </div>
                    </td>
                  </tr>
                </template>
              </table>
            </div>

            <div class="card-footer">
              <div class="row">
                <div class="col-md-6">
                  <div class="text-start"></div>
                </div>
                <div class="col-md-6">
                  <div class="text-end">
                    &nbsp;
                    <b-button type="submit" variant="primary"><i class="fa fa-save"></i> Simpan</b-button>
                  </div>
                </div>
              </div>
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </Layout>
</template>
